import logger from './logger';
import apiMiddleware from './apiCall';
import apiSlice from '../api/apiSlice';

const middlewares = [apiSlice.middleware, apiMiddleware];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}


export default middlewares;
