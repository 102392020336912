import { configureStore } from '@reduxjs/toolkit';
import middlewares from './middlewares';
import rootReducer from './slices';

function configureReduxStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    preloadedState,
  });

  return store;
}

export default configureReduxStore;
