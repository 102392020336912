import { createSlice } from '@reduxjs/toolkit';
import { inventoryConfig } from "./config";

const initialState = {
  config: inventoryConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    getStocks: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { stocks, pagination } = action.result;
          state.config.stocks = stocks;
          if (pagination) {
            state.config.pagination = pagination;
          } else {
            state.config.pagination = {...inventoryConfig.pagination}
          }
          state.error = {};
        } else if (action.error) {
            state.error.apiResult = action.error;
            state.errorDialog = {
              show: true,
              message: action.error.message ? action.error.message : action.error,
              title: 'Error'
          }
        }
      }
    },
    postStock: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { stock } = action.result;
          state.config.stock = stock;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: `Stock added successfully`,
            title: 'Success'
          };
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getStock: (state,action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { stock } = action.result;
          state.config.stock = stock;
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    updateStock: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { stock } = action.result;
          state.config.stock = stock;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: `Stock updated successfully`,
            title: 'Success'
          };
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    deleteStock: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { stock } = action.result;
          state.config.stock = stock;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: 'Stock deleted successfully',
            title: 'Success'
          }
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    editInputs: (state, action) => {
      const payload = action.payload;
      if (payload.error && payload.error.length > 0) {
        state.error[payload.name] = payload.error;
      } else {
        const error = state.error;
        delete error[payload.name];
        state.error = error;
      }
      state.config.inputs[payload.name] = payload.value;
    },
    resetInputs: (state) => {
      state.config.inputs = { ...inventoryConfig.inputs };
    },
    updatePagination: (state, action) => {
      state.config.pagination = {...state.config.pagination, ...action.payload};
    },
  },
});

export const {
   getStocks,
   postStock,
   getStock,
   updateStock,
   editInputs,
   resetInputs,
   setErrorDialog,
   updatePagination,
   deleteStock
  } = inventorySlice.actions;

export default inventorySlice.reducer;
