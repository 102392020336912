export const cartItemConfig = {
  cartItemInputs: {
    cartId: '',
    productId: null,
    variantId: null,
    quantity: 1,
  },
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

export const cartItemOptions = [
  { key: 'cartId', type: 'text', name: 'cartId', label: 'cartId', visible: false, editable: false },
  { key: 'productId', type: 'text', name: 'productId', label: 'productId', visible: false, editable: false },
  { key: 'variantId', type: 'text', name: 'variantId', label: 'Chosen Variant', visible: false, editable: false },
];

export const closeDialog = (dispatch, setErrorDialog) => {
  return () => {
    dispatch(setErrorDialog({alert: { 
      show: false, 
      message: '', 
      title: '' 
    }}));
  };
};

export const createDialog = (dispatch, setErrorDialog, errorDialog, customButtons) => {
  const handleCloseDialog = closeDialog(dispatch, setErrorDialog);
  const defaultButtons = [
    {
      text: 'Ok',
      onClick: handleCloseDialog,
    }
  ];

  const buttons = customButtons || defaultButtons;

  return {
    show: errorDialog.show,
    message: errorDialog.message,
    onClose: closeDialog,
    title: errorDialog.title,
    buttons: buttons,
  };
};
