import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storageHelper from '../layouts/wrappers/storageHelper';
import { refresh, signOut } from '../views/auth/auth.slice';
import { createMetaCookie  } from './helpers';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { loggedIn, permissions, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const intialRefreshAttempted = useRef(false);
//
  useEffect(() => {
    const refreshToken = storageHelper.get('token');
    if (!loggedIn && refreshToken) {
      intialRefreshAttempted.current = true;
      dispatch(
        refresh({
          params: {
            refreshToken: refreshToken.token,
          },
          method: {
            url: '/auth/refresh-tokens',
            action: 'POST',
          },
        })
      )
    } else {
      intialRefreshAttempted.current = true;
      if (!refreshToken && loggedIn) {
        // Only sign out if the user was logged in
          dispatch(signOut());
      }
    }
    createMetaCookie();
  }, []);

  const checkPermissions = (requiredPermissions) => {
    const allowedOnUI = ['user.dashboard.view', 'user.profile.view', 'user.profile.edit'] //user.customer.view possible permissions to be added];
    if (requiredPermissions.some((permission) => allowedOnUI.includes(permission))) return true;
    if (permissions && permissions.length === 0) return false;
    if (permissions && permissions.includes('*')) return true;
    return requiredPermissions.every((permission) => permissions.includes(permission));
  };

  return (
    <UserContext.Provider value={{ checkPermissions, user, loggedIn, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export function withUser(Component) {
  return function UserComponent(props) {
    const userContext = useUser();
    return <Component {...props} userContext={userContext} />;
  };
}

export const useUser = () => useContext(UserContext);
