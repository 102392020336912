import React from 'react';

const BikiniIcon = ({ width = 18, height = 18, fill = '#64748B', stroke = '#64748B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_239_3166)">
        <path
          d="M15 7.8759C15 7.674 14.8989 7.4898 14.7294 7.3827C14.3466 7.1418 12.4365 5.6829 12.438 0.5889C12.438 0.2643 12.1755 0 11.853 0H11.34C10.9695 0 10.7022 0.2313 10.6896 0.564C10.6221 2.6565 10.1394 5.1 9 5.1C7.8606 5.1 7.3779 2.6562 7.3104 0.5622C7.2978 0.2313 7.0305 0 6.66 0H6.147C5.8245 0 5.562 0.2643 5.562 0.5892C5.5635 5.6829 3.6537 7.1421 3.2703 7.383C3.1011 7.4895 3 7.674 3 7.8759V8.3571C3 8.6169 3.1749 8.8485 3.426 8.9202C3.7395 9.0093 5.0685 9.3 9 9.3C12.9315 9.3 14.2605 9.0093 14.5743 8.9202C14.8251 8.8485 15 8.6169 15 8.3571V7.8759ZM9 8.7C5.0463 8.7 3.7998 8.4027 3.6 8.3571L3.5901 7.8906C4.194 7.5108 6.1635 5.8623 6.147 0.5997H6.66C6.6837 0.5997 6.7008 0.6027 6.7116 0.6057C6.7635 2.1561 7.0917 5.6997 9 5.6997C10.9083 5.6997 11.2365 2.1561 11.2884 0.6057C11.2992 0.6027 11.3163 0.5997 11.34 0.5997L11.838 0.5889C11.8365 5.8347 13.7856 7.4937 14.4003 7.8843L14.4099 8.3427C14.2002 8.4021 12.9537 8.6994 9 8.6994V8.7Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M15 12.3325V11.1919C15 11.0062 14.9166 10.834 14.7711 10.7194C14.6259 10.6057 14.4411 10.5646 14.2617 10.6084C13.5027 10.7917 11.8098 11.1001 9 11.1001C6.1902 11.1001 4.4973 10.792 3.738 10.6084C3.5589 10.5646 3.3738 10.606 3.2289 10.7194C3.0837 10.834 3 11.0062 3 11.1919V12.3325C3 12.6289 3.2199 12.8839 3.5118 12.9262C4.4037 13.0543 7.3446 13.7203 7.752 16.918C7.8309 17.5351 8.3517 18.0001 8.9634 18.0001H9.0366C9.6483 18.0001 10.1691 17.5348 10.248 16.918C10.6551 13.7206 13.5963 13.0543 14.4882 12.9262C14.7801 12.8839 15 12.6292 15 12.3325ZM9.6528 16.8418C9.6114 17.1652 9.3525 17.3998 9.0366 17.3998H8.9634C8.6475 17.3998 8.3886 17.1652 8.3472 16.8418C7.8864 13.2238 4.5948 12.475 3.6 12.3325L3.597 11.1913C4.3821 11.3815 6.1281 11.6998 9 11.6998C11.8719 11.6998 13.6179 11.3812 14.4 11.1919L14.4027 12.3319C13.4052 12.4753 10.1136 13.2241 9.6528 16.8418Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_239_3166">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BikiniIcon;
