import { createSlice } from '@reduxjs/toolkit';
import { defaultConfig } from './config';

const initialState = {
  config: defaultConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: ''
  },
};

export const defaultSlice = createSlice({
  name: 'default',
  initialState,
  reducers: {
    getCustomers: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { clients, pagination } = action.result;
          state.config.customers = clients;
          if (pagination) {
            state.config.pagination = pagination;
          } else {
            state.config.pagination = {...defaultConfig.pagination};
          }
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getClients: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { clients, pagination } = action.result;
          state.config.clients = clients;
          if (pagination) {
            state.config.pagination = pagination;
          } else {
            state.config.pagination = {...defaultConfig.pagination};
          }
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getClient: (state,action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { client } = action.result;
          state.config.client = client;
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getActivities: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { activities, pagination } = action.result;
          state.config.activities = activities;
          if (pagination) {
            state.config.pagination = pagination;
          } else {
            state.config.pagination = {...defaultConfig.pagination};
          }
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getActivity: (state,action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { activity } = action.result;
          state.config.activity = activity;
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    editInputs: (state, action) => {
      const payload = action.payload;
      if (payload.error && payload.error.length > 0) {
        state.error[payload.name] = payload.error;
      } else {
        const error = state.error;
        delete error[payload.name];
        state.error = error;
      }
      state.config.inputs[payload.name] = payload.value;
    },
    resetInputs: (state) => {
      state.config.inputs = { ...defaultConfig.inputs };
    },
    updatePagination: (state, action) => {
      state.config.pagination = { ...state.config.pagination, ...action.payload };
    },
    clearActivities: (state) => {
      state.config.activities = defaultConfig.activities;
    }
  },
});

export const {
  getCustomers,
  getClients,
  setErrorDialog,
  getCustomer,
  getActivities,
  getActivity,
  editInputs,
  resetInputs,
  updatePagination,
  clearActivities
} = defaultSlice.actions;

export default defaultSlice.reducer;
