import { signOut } from '../../views/auth/auth.slice';
import storageHelper from '../../layouts/wrappers/storageHelper';

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const { method } = action?.payload || {};
    if (method) {
      action.methodRequestState = 'requested';
      next(action); // Proceed with the modified action

      const baseUrl = process.env.REACT_APP_API_URL;
      const fullUrl = `${baseUrl}${method.url}`;
      const token = sessionStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'x-meta': storageHelper.get('$meta'),
      };
      try {
        if (token) {
          headers.Authorization = `Bearer ${token || ''}`;
        }
        const response = await fetch(fullUrl, {
          method: method.action,
          headers,
          body: method.action.toLowerCase() === 'get' ? undefined : JSON.stringify(action.payload?.params || {}),
        });
        const data = await response.json();
        if (response.ok) {
          action.result = data;
        } else {
          action.error = data;
        }
        action.methodRequestState = 'finished';
      } catch (error) {
        if (error.status === 401 && method.url !== '/auth/refresh-tokens') {
          dispatch(signOut());
        }
        action.error = error?.message || 'Unknown error occurred';
      } finally {
        action.methodRequestState = 'finished';
      }
      next(action); // Proceed with the modified action after fetch
    } else {
      next(action);
    }
  };

export default apiMiddleware;
