import React, { useState, useEffect } from 'react';
import ProgressBar from './index';

const ProgressBarWrapper = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simulate loading progress
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100); // Adjust time interval as needed

    return () => clearInterval(interval);
  }, []);

  return <ProgressBar progress={progress} />;
};

export default ProgressBarWrapper;
