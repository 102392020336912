import storageHelper from '../layouts/wrappers/storageHelper';
import { v4 as uuidv4 } from 'uuid';

function obfuscateData(jsonString) {
  const dt = jsonString
    .split('')
    .map((char) => {
      return String.fromCharCode(char.charCodeAt(0) + 3);
    })
    .join('');
  return dt;
}

export const createMetaCookie = async () => {
  let sessionId = storageHelper.get('sessionId');
  if (!sessionId) {
    sessionId = uuidv4(); // Generate a new UUID
    storageHelper.set('sessionId', sessionId);
  }
  //const location = await getGeolocation();
  const $meta = { location: null, sessionId: sessionId };
  const serializedMeta = obfuscateData(JSON.stringify($meta));
  storageHelper.set('$meta', serializedMeta);
};

export const getMetaCookie = () => storageHelper.get('$meta');
