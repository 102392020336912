import { createSlice } from '@reduxjs/toolkit';
import { cartItemConfig } from '../../featureConfig/cartItemConfig'; // Adjust the import path as needed

const initialState = {
  config: cartItemConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

const cartItemSlice = createSlice({
  name: 'cartItems',
  initialState,
  reducers: {
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    updatePagination: (state, action) => {
      state.config.pagination = {
        ...state.config.pagination,
        ...action.payload,
      };
    },
    resetInputs: (state) => {
      state.config.cartItemInputs = { ...cartItemConfig.cartItemInputs };
    },
    editInputs: (state, action) => {
      const payload = action.payload;
      if (payload.error && payload.error.length > 0) {
        state.error[payload.name] = payload.error;
      } else {
        delete state.error[payload.name];
      }
      state.config.cartItemInputs[payload.name] = payload.value;
    },

  },
});

export const { setErrorDialog, updatePagination, resetInputs, editInputs } = cartItemSlice.actions;

export default cartItemSlice.reducer;
