import React from 'react';

const BabyIcon = ({ width = 18, height = 18, fill = '#64748B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_239_3162)">
        <path
          d="M18.0002 8.62498C17.9997 8.10776 17.8466 7.60219 17.5598 7.17173C17.2731 6.74128 16.8655 6.40512 16.3884 6.20548C16.0839 4.46725 15.1762 2.89206 13.825 1.75698C12.4738 0.6219 10.7656 -0.000366211 9.00091 -0.000366211C7.2362 -0.000366211 5.52802 0.6219 4.17681 1.75698C2.8256 2.89206 1.91791 4.46725 1.61341 6.20548C1.06891 6.43455 0.617594 6.84059 0.332441 7.35793C0.0472883 7.87527 -0.0550319 8.47367 0.042026 9.05636C0.139084 9.63906 0.429846 10.172 0.867294 10.569C1.30474 10.9659 1.8633 11.2038 2.45266 11.244C2.84002 12.066 3.36537 12.8156 4.00591 13.4602C3.06735 14.485 2.4717 15.777 2.30191 17.1562C2.28962 17.254 2.29671 17.3532 2.32278 17.4482C2.34884 17.5432 2.39338 17.6322 2.45383 17.71C2.51429 17.7878 2.58948 17.8529 2.67512 17.9016C2.76075 17.9504 2.85514 17.9817 2.95291 17.994C2.98377 17.9977 3.01482 17.9997 3.04591 18C3.22843 17.9998 3.40462 17.933 3.54144 17.8122C3.67827 17.6914 3.76634 17.5248 3.78916 17.3437C3.92513 16.2563 4.40161 15.2399 5.15041 14.4397C6.26726 15.2641 7.61228 15.7219 9.00016 15.75C10.3839 15.7217 11.725 15.2658 12.8394 14.445C13.5849 15.2437 14.0595 16.2573 14.1954 17.3415C14.2181 17.5227 14.3061 17.6894 14.4429 17.8104C14.5797 17.9314 14.756 17.9982 14.9387 17.9985C14.97 17.9983 15.0013 17.9963 15.0324 17.9925C15.2297 17.9678 15.4091 17.8657 15.5312 17.7087C15.6533 17.5518 15.708 17.3528 15.6834 17.1555C15.5148 15.7797 14.9221 14.4906 13.9877 13.467C14.6317 12.8204 15.1599 12.0679 15.5492 11.2425C16.2135 11.1983 16.8362 10.9034 17.2913 10.4175C17.7463 9.93149 17.9997 9.29075 18.0002 8.62498Z"
          fill={fill}
        />
        <path
          d="M7.125 9.75C7.74632 9.75 8.25 9.24632 8.25 8.625C8.25 8.00368 7.74632 7.5 7.125 7.5C6.50368 7.5 6 8.00368 6 8.625C6 9.24632 6.50368 9.75 7.125 9.75Z"
          fill={fill}
        />
        <path
          d="M10.875 9.75C11.4963 9.75 12 9.24632 12 8.625C12 8.00368 11.4963 7.5 10.875 7.5C10.2537 7.5 9.75 8.00368 9.75 8.625C9.75 9.24632 10.2537 9.75 10.875 9.75Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_239_3162">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BabyIcon;
