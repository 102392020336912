import { createSlice } from '@reduxjs/toolkit';
import storageHelper from '../../layouts/wrappers/storageHelper';
import { createMetaCookie } from '../../contexts/helpers';

const initialState = {
  user: null,
  tokens: null,
  loggedIn: false,
  permissions: [],
  error: {},
  inputs: {
    email: '',
    password: '',
  },
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { user, tokens, permissions } = action.result;
          state.user = user;
          state.tokens = tokens;
          state.permissions = permissions;
          storageHelper.set('token', tokens.refresh);
          sessionStorage.setItem('token', tokens.access.token);
          state.error = {};
          state.loggedIn = true;
          createMetaCookie();
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message,
            title: 'Error',
          };
          state.inputs.password = '';
        }
      }
    },
    refresh: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { user, tokens, permissions } = action.result;
          state.user = user;
          state.tokens = tokens;
          state.permissions = permissions;
          storageHelper.set('token', tokens.refresh);
          state.error = {};
          state.loggedIn = true;
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.loggedIn = false;
          state.errorDialog = {
            show: action.error.code === 401 ? false : true,
            message: action.error.message,
            title: 'Error',
          };
        }
      }
    },
    editInputs: (state, action) => {
      const payload = action.payload;
      if (payload.error && payload.error.length > 0) {
        state.error[payload.name] = payload.error;
      } else {
        const error = state.error;
        delete error[payload.name];
        state.error = error;
      }
      state.inputs[payload.name] = payload.value;
    },
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    signOut: (state) => {
      state.user = null;
      state.tokens = null;
      state.permissions = [];
      state.loggedIn = false;
      storageHelper.clear();
    },
  },
});

export const { signIn, editInputs, setErrorDialog, signOut, refresh } = authSlice.actions;

export default authSlice.reducer;
