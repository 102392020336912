import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import storageHelper from '../../layouts/wrappers/storageHelper';

export const apiSlice = createApi({
  reducerPath: 'api', // The key used in the Redux store
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL, // Base URL for all API requests
    prepareHeaders: (headers) => {
      // Include any custom headers here
      // For example, adding an Authorization header
      const token = sessionStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      // Add any other headers you need
      headers.set('x-meta', storageHelper.get('$meta'));
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Products', 'Inventory', 'Sales', 'Customers', 'Activities', 'ProductVariants'], // Tags for cache invalidation
  endpoints: () => ({}), // We will inject endpoints in feature-specific files
});

export default apiSlice;
