export const cartConfig = {
  cartInputs: {
    customerId: '',
    sessionId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    shippingAddress: '',
    networkCode: '',
    paymentNumber: '',
    billingAddress: '',
  },
  pagination: {
    pageNumber: 1,
    pageSize: 100,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
};

export const cartFormOptions = [
  { key: 'firstName', type: 'text', name: 'firstName', label: 'First Name', visible: true, editable: true },
  { key: 'lastName', type: 'text', name: 'lastName', label: 'Last Name', visible: true, editable: true },
  { key: 'email', type: 'email', name: 'email', label: 'Email', visible: true, editable: true },
  { key: 'phoneNumber', type: 'tel', name: 'phoneNumber', label: 'Phone Number', visible: true, editable: true },
  { key: 'shippingAddress', type: 'text', name: 'shippingAddress', label: 'Shipping Address', visible: true, editable: true },
  { key: 'paymentNumber', type: 'tel', name: 'paymentNumber', label: 'Payment Phone Number', visible: true, editable: true },
  { key: 'billingAddress', type: 'text', name: 'billingAddress', label: 'Billing Address', visible: true, editable: true },
];

export const networkCodeMappings = {
  Mpesa: "63902",
  Airtel: "63903",
  TKash: "63907",
};

export const closeDialog = (dispatch, setErrorDialog) => {
  return () => {
    dispatch(setErrorDialog({alert: {
      show: false,
      message: '',
      title: ''
    }}));
  };
};

export const createDialog = (dispatch, setErrorDialog, errorDialog, customButtons) => {
  const handleCloseDialog = closeDialog(dispatch, setErrorDialog);
  const defaultButtons = [
    {
      text: 'Ok',
      onClick: handleCloseDialog,
    }
  ];

  const buttons = customButtons || defaultButtons;

  return {
    show: errorDialog.show,
    message: errorDialog.message,
    onClose: closeDialog,
    title: errorDialog.title,
    buttons: buttons,
  };
};
