import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { editInputs } from './redux/slices/cartSlice';
import { useDispatch } from 'react-redux';
import storageHelper from './layouts/wrappers/storageHelper';

// Import UserProvider
import { UserProvider } from './contexts/userContext';
import ProgressBarWrapper from './components/progressBar/appSuspense';

// import LoadingSpinner from './components/spinners/LoadingWithBlur'; // Placeholder loading component
const AuthLayout = lazy(() => import('./layouts/auth'));
const AdminLayout = lazy(() => import('./layouts/admin'));
const CustomerLayout = lazy(() => import('./layouts/customer/index'));
const AuthenticatedRoute = lazy(() => import('./layouts/wrappers/authenticatedRoute'));
const ProtectedAuthRoute = lazy(() => import('./layouts/wrappers/protectAuthRoute'));

const App = () => {
  document.documentElement.classList.remove('dark');
  return (
    <UserProvider>
      <Suspense fallback={<ProgressBarWrapper />}>
        <Routes>
          <Route
            path="auth/*"
            element={
              <ProtectedAuthRoute>
                <AuthLayout />
              </ProtectedAuthRoute>
            }
          />
          <Route
            path="admin/*"
            element={
              <AuthenticatedRoute>
                <AdminLayout />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="customer/*"
            element={
             <CustomerLayout /> 
            }
          >
          </Route>
          <Route path="/" element={<Navigate to="/customer" replace />} />
        </Routes>
      </Suspense>
    </UserProvider>
  );
};

export default App;
