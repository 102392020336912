import { createSlice } from '@reduxjs/toolkit';
import { cartConfig } from '../../featureConfig/cartConfig'; // Adjust the import path as needed

const initialState = {
  config: cartConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

const cartSlice = createSlice({
  name: 'carts',
  initialState,
  reducers: {
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    updatePagination: (state, action) => {
      state.config.pagination = {
        ...state.config.pagination,
        ...action.payload,
      };
    },
    resetInputs: (state) => {
      state.config.cartInputs = { ...cartConfig.cartInputs };
    },
    editInputs: (state, action) => {
      const { name, value, error } = action.payload;
      if (error && error.length > 0) {
        state.error[name] = error;
      } else {
        delete state.error[name];
      }
      state.config.cartInputs[name] = value;
    },
  },
});

export const { setErrorDialog, updatePagination, resetInputs, editInputs } = cartSlice.actions;

export default cartSlice.reducer;
