export const inventoryConfig = {
  columns: [
    { name: 'Product Id', field: 'productId'},
    { name: 'Product Code', field: 'code'},
    { name: 'Product Name', field: 'productName'},
    { name: 'Quantity', field: 'stockQuantity'},
    { name: 'Reorder Level', field: 'reorderLevel'},
    { name: 'Reorder Quantity', field: 'reorderQuantity'},
    { name: 'Smallest Unit Of Quantity', field: 'smallestUnitOfQuantity'},
  ],
  options: [
    { label: 'Update', action: 'update', icon: 'FaPencilAlt' },
    { label: 'Delete', action: 'delete', icon: 'FaTrash' },
    { label: 'View', action: 'view', icon: 'FaEye' },
  ],
  selected: [],
  stocks: [],
  stock: {},
  inputs: {
    productId: '',
    stockQuantity: '',
    reorderLevel: '',
    reorderQuantity: '',
    smallestUnitOfQuantity: '',
  },
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
};


export const mapStockToFormInputs = (stock) => {
  return {
    productId: stock.productName,
    stockQuantity: stock.stockQuantity,
    reorderLevel: stock.reorderLevel,
    reorderQuantity: stock.reorderQuantity,
    smallestUnitOfQuantity: stock.smallestUnitOfQuantity
  };
};

export const staticFormOptions = [
  { key: 'productId', label: 'Product', name: 'productId', type: 'select', editable: false },
  { key: 'stockQuantity', label: 'Quantity', name: 'stockQuantity', type: 'text', editable: true },
  { key: 'reorderLevel', label: 'Reorder Level', name: 'reorderLevel', type: 'text', editable: true },
  { key: 'reorderQuantity', label: 'Reorder Quantity', name: 'reorderQuantity', type: 'text', editable: true },
  { key: 'smallestUnitOfQuantity', label: 'Smallest Unit Of Quantity', name: 'smallestUnitOfQuantity', type: 'text', editable: true },
];

export const layoutConfig = {
legend: "Inventory Details",
description: "Add inventorys details",
gridClass: "grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
};


export const closeDialog = (dispatch, setErrorDialog) => {
  return () => {
    dispatch(setErrorDialog({alert: { show: false, message: '', title: '' }}));
  }
};

export const createDialog = (dispatch, setErrorDialog, errorDialog, customButtons) => {
  const handleCloseDialog = closeDialog(dispatch, setErrorDialog);
  const defaultButtons = [
    {
      text: 'Ok',
      onClick: handleCloseDialog,
    }
  ];

  const buttons = customButtons || defaultButtons;

  return {
    show: errorDialog.show,
    message: errorDialog.message,
    onClose: closeDialog,
    title: errorDialog.title,
    buttons: buttons,
  };
};

export const mapFormOptions =  (key, dataMappings) => {
  const mapping = dataMappings[key];
  if (mapping) {
    return mapping.data.map(item => ({
      id: item[mapping.idField],
      name: item[mapping.nameField],
    }));
  }
  return [];
};
