export const catalogConfig = {
  categories: [],
  subCategories: [],
  category: {},
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
};
