import React from 'react';

const BeanieIcon = ({ width = 18, height = 18, fill = '#64748B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_239_3169)">
        <path
          d="M16.4265 12.9735C16.0298 9.10875 13.8285 4.21725 10.3238 3.2025C10.6643 2.86275 10.875 2.394 10.875 1.875C10.875 0.83925 10.0358 0 9 0C7.96425 0 7.125 0.83925 7.125 1.875C7.125 2.394 7.33575 2.86275 7.67625 3.2025C4.1715 4.21725 1.97025 9.10875 1.5735 12.9735C0.64875 13.38 0 14.3025 0 15.375C0 16.8225 1.1775 18 2.625 18H15.375C16.8225 18 18 16.8225 18 15.375C18 14.3025 17.3512 13.38 16.4265 12.9735ZM9 4.5C11.892 4.5 14.3723 8.70375 14.898 12.75H3.102C3.16725 12.2498 3.26475 11.7472 3.387 11.25H12C12.4148 11.25 12.75 10.914 12.75 10.5C12.75 10.086 12.4148 9.75 12 9.75H3.8445C4.89975 6.86325 6.8325 4.5 9 4.5ZM15.375 16.5H2.625C2.00475 16.5 1.5 15.9952 1.5 15.375C1.5 14.7548 2.00475 14.25 2.625 14.25H15.375C15.9952 14.25 16.5 14.7548 16.5 15.375C16.5 15.9952 15.9952 16.5 15.375 16.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_239_3169">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BeanieIcon;
