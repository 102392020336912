import BabyIcon from '../../../../components/icons/Baby';
import BeanieIcon from '../../../../components/icons/Beanie';
import BikiniIcon from '../../../../components/icons/Bikini';
import BottleIcon from '../../../../components/icons/Bottle';
import LampIcon from '../../../../components/icons/Lamp';

export const productConfig = {
  listViewColumns: [
    { name: 'Product Id', field: 'productId'},
    { name: 'Product Code', field: 'code'},
    { name: 'Product Name', field: 'productName'},
    { name: 'Description', field: 'description'},
    { name: 'Category', field: 'categoryName'},
    { name: 'Unit Cost', field: 'unitCost'},
    { name: 'Wholesale Price', field: 'wholesalePrice'},
    { name: 'Retail Price', field: 'retailPrice'},
    { name: 'Active For Sale', field: 'isActiveForSale'},
  ],
  detailViewColumns: [
    { name: 'Product Id', field: 'productId'},
    { name: 'Product Code', field: 'code'},
    { name: 'Product Name', field: 'productName'},
    { name: 'Description', field: 'description'},
    { name: 'Category', field: 'categoryName'},
    { name: 'Packaging Unit', field: 'packagingUnit'},
    { name: 'Unit Of Quantity', field: 'unitOfQuantity'},
    { name: 'Tax Type', field: 'taxType'},
    { name: 'Product Type', field: 'productType'},
    { name: 'Unit Cost', field: 'unitCost'},
    { name: 'Wholesale Price', field: 'wholesalePrice'},
    { name: 'Retail Price' ,field: 'retailPrice'},
    { name: 'Active For Sale', field: 'isActiveForSale'},
  ],
  options: [
    { label: 'Update', action: 'update', icon: 'FaPencilAlt' },
    { label: 'Delete', action: 'delete', icon: 'FaTrash' },
    { label: 'View', action: 'view', icon: 'FaEye' },
  ],
  selected: [],
  products: [],
  product: {},
  inputs: {
    productName: '',
    code: '',
    description: '',
    categoryId: '',
    packagingUnitId: '',
    unitOfQuantityId: '',
    taxTypeId: '',
    productTypeId: '',
    unitCost: '',
    wholesalePrice: '',
    retailPrice: '',
    isActiveForSale: '',
  },
  pagination: {
    pageNumber: 1,
    pageSize: 6,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
};

export const mapProductToFormInputs = (product) => {
  return {
    productName: product.productName,
    code: product.code,
    description: product.description,
    categoryId: product.categoryId,
    packagingUnitId: product.packagingUnitId,
    unitOfQuantityId: product.unitOfQuantityId,
    taxTypeId: product.taxTypeId,
    productTypeId: product.productTypeId,
    unitCost: product.unitCost,
    wholesalePrice: product.wholesalePrice,
    retailPrice: product.retailPrice,
    isActiveForSale: product.isActiveForSale,
  };
};

export const staticFormOptions = [
  { key: 'productName', label: 'Product Name', name: 'productName', type: 'text', editable: true },
  { key: 'productCode', label: 'Product Code', name: 'code', type: 'text', editable: true },
  { key: 'description', label: 'Description', name: 'description', type: 'textarea', editable: true },
  { key: 'categoryId', label: 'Category', name: 'categoryId', type: 'select', editable: true},
  { key: 'packagingUnitId', label: 'Packaging Unit', name: 'packagingUnitId', type: 'select', editable: true },
  { key: 'unitOfQuantityId', label: 'Unit Of Quantity', name: 'unitOfQuantityId', type: 'select', editable: true },
  { key: 'taxTypeId', label: 'Tax Type', name: 'taxTypeId', type: 'select', editable: true },
  { key: 'productTypeId', label: 'Product Type', name: 'productTypeId', type: 'select', editable: true },
  { key: 'image', label: 'Product Image', name: 'image', type: 'file', editable: true },
  { key: 'unitCost', label: 'Unit Cost', name: 'unitCost', type: 'text', editable: true },
  { key: 'wholesalePrice', label: 'Wholesale Price', name: 'wholesalePrice', type: 'text', editable: true },
  { key: 'retailPrice', label: 'Retail Price', name: 'retailPrice', type: 'text', editable: true },
  { key: 'isActiveForSale', label: 'Active For Sale', name: 'isActiveForSale', type: 'text', editable: true },
];

export const layoutConfig = {
legend: "Product Details",
description: "Add products details to add new product",
gridClass: "grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
};


export const closeDialog = (dispatch, setErrorDialog) => {
  return () => {
    dispatch(setErrorDialog({alert: { 
      show: false, 
      message: '', 
      title: '' 
    }}));
  };
};

export const createDialog = (dispatch, setErrorDialog, errorDialog, customButtons) => {
  const handleCloseDialog = closeDialog(dispatch, setErrorDialog);
  const defaultButtons = [
    {
      text: 'Ok',
      onClick: handleCloseDialog,
    }
  ];

  const buttons = customButtons || defaultButtons;

  return {
    show: errorDialog.show,
    message: errorDialog.message,
    onClose: closeDialog,
    title: errorDialog.title,
    buttons: buttons,
  };
};

export const mapFormOptions =  (key, dataMappings) => {
  const mapping = dataMappings[key];
  if (mapping) {
    return mapping.data.map(item => ({
      id: item[mapping.idField],
      name: item[mapping.nameField],
    }));
  }
  return [];
};

export const getCategoryIcon = (categoryName) => {
  switch (categoryName) {
    case 'Bikinis':
      return <BikiniIcon />;
    case 'HeadWear':
      return <BeanieIcon />;
    case 'Bottle Art':
      return <BottleIcon />;
    case 'Kids':
      return <BabyIcon />;
    case 'Lampshades':
      return <LampIcon />;
    default:
      return null; // If there's no specific icon for the category
  }
};
