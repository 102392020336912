import { createSlice } from '@reduxjs/toolkit';
import { productVariantConfig } from '../../featureConfig/productVariantConfig';

const initialState = {
  config: productVariantConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

const productVariantSlice = createSlice({
  name: 'productVariants',
  initialState,
  reducers: {
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    editInputs: (state, action) => {
      const { name, value, error } = action.payload;
      if (error && error.length > 0) {
        state.error[name] = error;
      } else {
        delete state.error[name];
      }
      state.config.inputs[name] = value;
    },
    resetInputs: (state) => {
      state.config.inputs = { ...productVariantConfig.inputs };
    },
    updatePagination: (state, action) => {
      state.config.pagination = {
        ...state.config.pagination,
        ...action.payload,
      };
    },
  },
});

export const { setErrorDialog, editInputs, resetInputs, updatePagination } = productVariantSlice.actions;

export default productVariantSlice.reducer;
