import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  alert: {},
  error: {},
  allowScrollAboveNavbar: true,
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
  locations: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    showAlert: (state, action) => {
      state.alert = action.payload.alert;
    },
    toggleDarkMode: (state) => {
      state.darkmode = !state.darkmode;
      localStorage.setItem('darkmode', String(state.darkmode));
    },
    toggleScrollAboveNavbar: (state) => {
      state.allowScrollAboveNavbar = !state.allowScrollAboveNavbar;
    },
  },
});

export const {
  setLoading,
  showAlert,
  toggleDarkMode,
  toggleScrollAboveNavbar,
} = appSlice.actions;

export default appSlice.reducer;
