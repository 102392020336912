import { combineReducers } from '@reduxjs/toolkit';
import authSlice from '../views/auth/auth.slice';
import appSlice from './app.slice';
//import userSlice from '../views/admin/users/user.slice';
//import rolesSlice from '../views/admin/rolesPermissions/roles.slice';
import customerCategoriesSlice from '../views/customer/catalog/categories/categories.slice';
import adminProductsSlice from '../views/admin/catalog/products/products.slice';
import customerProductSlice from '../views/customer/catalog/products/products.slice'
import inventorySlice from '../views/admin/inventory/inventory.slice';
import defaultSlice from '../views/customer/default/default.slice';
import cartItemSlice from './slices/cartItemSlice';
import cartSlice from './slices/cartSlice';
import apiSlice from './api/apiSlice';
import customerProductVariantSlice from './slices/productVariantSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  app: appSlice,
//  users: userSlice,
//  roles: rolesSlice,
  customerCategories: customerCategoriesSlice,
  adminProducts: adminProductsSlice,
  customerProducts: customerProductSlice,
  inventory: inventorySlice,
  default: defaultSlice,
  api: apiSlice.reducer,
  cartItems: cartItemSlice,
  carts: cartSlice,
  productVariants: customerProductVariantSlice,
//  items: itemSlice,
//  sales: salesSlice,
});

export default rootReducer;
