export const productVariantConfig = {
  productVariants: [],
  productVariant: {},
  productVariantInputs: {
    productId: '',
    colourId: '',
    sizeId: '',
    design: '',
    sku: '',
    image: '',
    thumbnail: '',
    unitCost: '',
    wholesalePrice: '',
    retailPrice: '',
    isActiveForSale: '',
    isFixedPrice: '',
  },
  pagination: {
    pageNumber: 1,
    pageSize: 100,
    recordsTotal: 0,
    pagesTotal: 0,
  },
  paginationOptions: [5, 10, 20, 50, 100],
};

export const closeDialog = (dispatch, setErrorDialog) => {
  return () => {
    dispatch(setErrorDialog({alert: {
      show: false,
      message: '',
      title: ''
    }}));
  };
};

export const createDialog = (dispatch, setErrorDialog, errorDialog, customButtons) => {
  const handleCloseDialog = closeDialog(dispatch, setErrorDialog);
  const defaultButtons = [
    {
      text: 'Ok',
      onClick: handleCloseDialog,
    }
  ];

  const buttons = customButtons || defaultButtons;

  return {
    show: errorDialog.show,
    message: errorDialog.message,
    onClose: closeDialog,
    title: errorDialog.title,
    buttons: buttons,
  };
};
