import { createSlice } from '@reduxjs/toolkit';
import { catalogConfig } from './config';


const initialState = {
  config: catalogConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: '',
  },
};

export const categoriesSlice = createSlice({
  name: 'customerCategories',
  initialState,
  reducers: {
    getCategories: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { categories, pagination } = action.result;
          state.config.categories = categories;
          state.config.pagination = pagination;
          state.error = {};
        } else if (action.error) {
            state.error.apiResult = action.error;
            state.errorDialog = {
              show: true,
              message: action.error.message ? action.error.message : action.error,
              title: 'Error'
          }
        }
      }
    },
    getSubCategories: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { subcategories, pagination } = action.result;
          state.config.subCategories = subcategories;
          state.config.pagination = pagination;
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    getCategoryDetails: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { category } = action.result;
          state.config.category = category;
          state.error = {};
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    updatePagination: (state, action) => {
      state.config.pagination = { ...state.config.pagination, ...action.payload };
    }
  },
});

export const {
  getCategories,
  getSubCategories,
  getCategoryDetails,
  setErrorDialog,
  updatePagination
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
