import { createSlice } from '@reduxjs/toolkit';
import { productConfig } from '../../../customer/catalog/products/config';

const initialState = {
  config: productConfig,
  error: {},
  errorDialog: {
    show: false,
    message: '',
    title: ''
  },
};

export const productsSlice = createSlice({
  name: 'adminProducts',
  initialState,
  reducers: {
    postProduct: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { product } = action.result;
          state.config.product = product;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: `Product: ${product.productName} added successfully`,
            title: 'Success'
          };
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    updateProduct: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { product } = action.result;
          state.config.product = product;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: `Product: ${product.productName} updated successfully`,
            title: 'Success'
          };
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    deleteProduct: (state, action) => {
      if (action.methodRequestState === 'finished') {
        if (action.result) {
          const { product } = action.result;
          state.config.product = product;
          state.error = {};
          state.errorDialog = {
            show: true,
            message: 'Product deleted successfully',
            title: 'Success'
          }
        } else if (action.error) {
          state.error.apiResult = action.error;
          state.errorDialog = {
            show: true,
            message: action.error.message ? action.error.message : action.error,
            title: 'Error'
          }
        }
      }
    },
    setErrorDialog: (state, action) => {
      state.errorDialog = action.payload.alert;
    },
    editInputs: (state, action) => {
      const payload = action.payload;
      if (payload.error && payload.error.length > 0) {
        state.error[payload.name] = payload.error;
      } else {
        const error = state.error;
        delete error[payload.name];
        state.error = error;
      }
      state.config.inputs[payload.name] = payload.value;
    },
    resetInputs: (state) => {
      state.config.inputs = { ...productConfig.inputs };
    },
    updatePagination: (state, action) => {
      state.config.pagination = { ...state.config.pagination, ...action.payload };
    }
  },
});

export const {
  setErrorDialog,
  postProduct,
  updateProduct,
  deleteProduct,
  editInputs,
  resetInputs,
  updatePagination
} = productsSlice.actions;

export default productsSlice.reducer;
