import React from 'react';

const LampIcon = ({ width = 18, height = 18, fill = '#64748B' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_239_3156" fill="white">
        <path d="M15.1876 10.125H2.81259C2.71994 10.1245 2.62884 10.1012 2.5474 10.057C2.46595 10.0128 2.39667 9.94925 2.34571 9.87187C2.29532 9.79539 2.26437 9.70776 2.25555 9.61659C2.24672 9.52542 2.2603 9.43348 2.29509 9.34875L5.10759 2.59875C5.14979 2.49603 5.22145 2.40809 5.31354 2.34603C5.40563 2.28397 5.51403 2.25055 5.62509 2.25H12.3751C12.4861 2.25055 12.5945 2.28397 12.6866 2.34603C12.7787 2.40809 12.8504 2.49603 12.8926 2.59875L15.7051 9.34875C15.7399 9.43348 15.7535 9.52542 15.7446 9.61659C15.7358 9.70776 15.7049 9.79539 15.6545 9.87187C15.6035 9.94925 15.5342 10.0128 15.4528 10.057C15.3713 10.1012 15.2802 10.1245 15.1876 10.125ZM3.65634 9H14.3438L11.9982 3.375H6.00196L3.65634 9Z" />
      </mask>
      <path
        d="M15.1876 10.125H2.81259C2.71994 10.1245 2.62884 10.1012 2.5474 10.057C2.46595 10.0128 2.39667 9.94925 2.34571 9.87187C2.29532 9.79539 2.26437 9.70776 2.25555 9.61659C2.24672 9.52542 2.2603 9.43348 2.29509 9.34875L5.10759 2.59875C5.14979 2.49603 5.22145 2.40809 5.31354 2.34603C5.40563 2.28397 5.51403 2.25055 5.62509 2.25H12.3751C12.4861 2.25055 12.5945 2.28397 12.6866 2.34603C12.7787 2.40809 12.8504 2.49603 12.8926 2.59875L15.7051 9.34875C15.7399 9.43348 15.7535 9.52542 15.7446 9.61659C15.7358 9.70776 15.7049 9.79539 15.6545 9.87187C15.6035 9.94925 15.5342 10.0128 15.4528 10.057C15.3713 10.1012 15.2802 10.1245 15.1876 10.125ZM3.65634 9H14.3438L11.9982 3.375H6.00196L3.65634 9Z"
        fill={fill}
      />
      <path
        d="M13.5 16.3125H4.5C4.35082 16.3125 4.20774 16.2532 4.10225 16.1477C3.99676 16.0423 3.9375 15.8992 3.9375 15.75C3.9375 15.6008 3.99676 15.4577 4.10225 15.3523C4.20774 15.2468 4.35082 15.1875 4.5 15.1875H13.5C13.6492 15.1875 13.7923 15.2468 13.8977 15.3523C14.0032 15.4577 14.0625 15.6008 14.0625 15.75C14.0625 15.8992 14.0032 16.0423 13.8977 16.1477C13.7923 16.2532 13.6492 16.3125 13.5 16.3125Z"
        fill={fill}
      />
      <defs>
        <mask id="clip0_239_3156" fill="white">
          <rect width="18" height="18" fill="white" />
        </mask>
      </defs>
    </svg>
  );
};

export default LampIcon;
