import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div className="absolute left-0 top-0 z-50 h-1 w-full bg-gray-700">
      <div className="h-full bg-brand-500" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
